import { Flex, Skeleton as MantineSkeleton, SkeletonProps, Space } from '@mantine/core'

import { useScreenSize } from '~/hooks'

const Skeleton = (props: SkeletonProps) => (
  <MantineSkeleton
    sx={{ borderRadius: 18 }}
    {...props}
  />
)

export const SellerCardPlaceholder = () => {
  const { isMobileScreen } = useScreenSize()
  const imageWidth = isMobileScreen ? 120 : 170
  const imageHeight = isMobileScreen ? 160 : 224

  if (isMobileScreen) {
    return (
      <Flex
        direction="column"
        gap={19}
      >
        <Flex
          justify="space-between"
          gap={13}
        >
          <MantineSkeleton
            width={imageWidth}
            height={imageHeight}
            miw={imageWidth}
            mih={imageHeight}
          />
          <Flex
            w="100%"
            direction="column"
            gap={16}
          >
            <Flex
              gap={8}
              direction="column"
            >
              <Skeleton
                width={180}
                height={22}
              />
              <Skeleton
                width={112}
                height={16}
              />
            </Flex>

            <Flex
              direction="column"
              gap={5}
            >
              <Skeleton
                height={12}
                width={213}
              />
              <Skeleton
                height={12}
                width={180}
              />
              <Skeleton
                height={12}
                width={203}
              />
            </Flex>

            <Space h={8} />

            <Flex gap={5}>
              <Skeleton
                height={12}
                width={48}
              />
              <Skeleton
                height={12}
                width={52}
              />
              <Skeleton
                height={12}
                width={41}
              />
              <Skeleton
                height={12}
                width={44}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          justify="space-between"
          align="center"
        >
          <Flex gap={4}>
            <Skeleton
              height={16}
              width={69}
            />
            <Skeleton
              height={16}
              width={74}
            />
          </Flex>

          <Skeleton
            height={16}
            width={72}
          />
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex
      justify="space-between"
      gap={24}
    >
      <MantineSkeleton
        width={imageWidth}
        height={imageHeight}
        miw={imageWidth}
        mih={imageHeight}
      />
      <Flex
        w="100%"
        direction="column"
        gap={16}
      >
        <Flex
          direction="column"
          gap={8}
        >
          <Flex
            gap={8}
            justify="space-between"
          >
            <Skeleton
              height={24}
              width={196}
            />

            <Flex gap={8}>
              <Skeleton
                height={24}
                width={24}
                circle
              />
              <Skeleton
                height={24}
                width={24}
                circle
              />
            </Flex>
          </Flex>
          <Skeleton
            height={16}
            width={148}
          />
        </Flex>

        <Flex
          direction="column"
          gap={5}
        >
          <Skeleton
            height={12}
            width={369}
          />
          <Skeleton
            height={12}
            width={289}
          />
          <Skeleton
            height={12}
            width={340}
          />
        </Flex>

        <Space h={2} />

        <Flex
          direction="column"
          justify="space-between"
          h="100%"
        >
          <Flex gap={5}>
            <Skeleton
              height={12}
              width={48}
            />
            <Skeleton
              height={12}
              width={52}
            />
            <Skeleton
              height={12}
              width={32}
            />
            <Skeleton
              height={12}
              width={40}
            />
            <Skeleton
              height={12}
              width={64}
            />
          </Flex>

          <Flex
            justify="space-between"
            align="center"
          >
            <Flex gap={12}>
              <Skeleton
                height={24}
                width={48}
              />
              <Skeleton
                height={24}
                width={48}
              />
            </Flex>
            <Skeleton
              style={{ justifySelf: 'end' }}
              height={24}
              width={196}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
