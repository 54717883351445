import { Divider, Flex, Modal } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { newThatchDialogStyle } from '~/components/shared/dialog'
import { ProfileBasicSummaryType, ProfileSummaryType, ServiceType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

// eslint-disable-next-line import/no-cycle
import { BoardAuthor } from '../board/BoardAuthor'
import ProfileCardSection from '../buyer/service-details/ProfileCardSection'
import SellerInfoSection from '../buyer/service-details/SellerInfoSection'
import { ThatchButton } from '../shared/ThatchButton'
import { SvgIcon, SvgIconType } from '../shared/image/SvgIcon'
import { LoadingSpinner } from '../shared/loading/LoadingSpinnerDynamic'
import { Typography } from '../shared/text/Typography'
import { getVideoUrl } from '../shared/video'
import Player from '../video/Player'
import { useProfielSummary } from './hooks/useProfileSummary'

const ServiceCard = ({
  title,
  subtitle,
  iconType,
  isMobileScreen,
  redirectToService,
}: {
  title: string
  subtitle: string
  iconType: SvgIconType
  isMobileScreen: boolean
  redirectToService: () => void
}) => (
  <Flex
    p={isMobileScreen ? '12px 8px' : 16}
    maw={isMobileScreen ? '100%' : 470}
    sx={{
      border: '1px solid rgba(0, 0, 0, 0.10)',
      borderRadius: 4,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#F3F2EF',
      },
    }}
    justify="space-between"
    align="center"
    gap={4}
    onClick={redirectToService}
  >
    <Flex gap={12}>
      <Flex
        miw={isMobileScreen ? 40 : 64}
        h={isMobileScreen ? 58 : 64}
        bg="appPaper.0"
        align="center"
        justify="center"
      >
        <SvgIcon
          width={isMobileScreen ? 24 : 32}
          height={isMobileScreen ? 24 : 32}
          type={iconType}
        />
      </Flex>

      <Flex
        direction="column"
        mt={-1.5}
        gap={8}
      >
        <Typography variant="body1">{title}</Typography>
        <Typography
          variant="caption"
          color="appPlaceholder.0"
        >
          {subtitle}
        </Typography>
      </Flex>
    </Flex>

    <IconChevronRight />
  </Flex>
)

interface AvailableForHireModalProps {
  author: ProfileBasicSummaryType
  onClose: () => void
  saveCurrentStateToQuery?: boolean
}
export const AvailableForHireModal: React.FC<AvailableForHireModalProps> = ({
  onClose,
  author,
  saveCurrentStateToQuery,
}) => {
  const router = useRouter()
  const { isMobileScreen } = useScreenSize()
  const { profileSummary, isLoading } = useProfielSummary({ username: author.username })

  const { classes: dialogClasses } = newThatchDialogStyle({})

  // const videoRef = useRef<HTMLVideoElement>(null)
  const user = { ...author, ...profileSummary }
  const containsServiceType = (type: ServiceType) => !!user?.[type] && !user?.[type]?.disabled

  const height = isMobileScreen ? '100%' : '70vh'

  const VideoPlayer = useCallback(() => {
    const videoUrl = getVideoUrl(user.sellerIntroVideo as string)
    console.info('Showing video player for URL: %s', videoUrl)
    return (
      <Player
        width={isMobileScreen ? '100%' : 350}
        height={isMobileScreen ? '100%' : 622}
        url={videoUrl}
      />
    )

    // return (
    //   <>
    //     <Flex
    //       justify="center"
    //       align="start"
    //       mb={isMobileScreen ? 0 : 8}
    //       sx={{ height }}
    //     >
    //       <video
    //         id="my_video"
    //         ref={videoRef}
    //         controls
    //         disablePictureInPicture
    //         controlsList="nodownload noplaybackrate"
    //         src={videoUrl}
    //         style={{
    //           width: '100%',
    //           maxWidth: 350,
    //           maxHeight: height,
    //         }}
    //         onError={e => {
    //           console.error(e.currentTarget.error)
    //           notify(true, e.currentTarget.error?.message, 'Failed to load video')
    //         }}
    //       >
    //         Sorry, your browser does not support embedded videos.
    //       </video>
    //     </Flex>
    //   </>
    // )
  }, [isMobileScreen, user?.sellerIntroVideo])

  const CustomDivider = useCallback(
    () => <Divider my={isMobileScreen ? 32 : 48} />,
    [isMobileScreen]
  )

  const redirect = async (path: string) => {
    if (saveCurrentStateToQuery) {
      await router.push(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            scroll: window.scrollY,
            tab: 'experts',
            seller: author.username,
          },
        },
        undefined,
        { shallow: true }
      )
    }
    router.push(path)
  }

  return (
    <Modal
      opened
      centered
      scrollAreaComponent={Modal.NativeScrollArea}
      fullScreen={isMobileScreen}
      size="auto"
      classNames={{
        root: dialogClasses.modalRoot,
        header: dialogClasses.modalHeader,
        title: dialogClasses.modalTitle,
        close: dialogClasses.modalCloseButton,
        body: dialogClasses.modalBody,
        ...(isMobileScreen ? { content: dialogClasses.modalContent } : {}),
      }}
      title={
        <BoardAuthor
          author={{ ...user, hireable: false }} // hireable to false to redirect to profile on click
          desktopPhotoSize={40}
          mobilePhotoSize={40}
          photoGap={12}
          titleVariant="body2SerifItalic"
          showAvailableForHireTag={true}
          containerStyle={{ margin: 0 }}
          trackingSource="profile" // TODO(shane): remove this once all uses are tracked down.
        />
      }
      onClose={onClose}
    >
      <Flex
        p={isMobileScreen ? '32px 16px 60px 16px' : '32px 40px'}
        direction={isMobileScreen ? 'column' : 'row'}
        gap={32}
      >
        {!isMobileScreen && user.sellerIntroVideo && <VideoPlayer />}

        <Flex
          direction="column"
          sx={{ overflowY: 'auto', height, paddingRight: 10 }}
        >
          {isLoading ? (
            <LoadingSpinner
              color="black"
              customStyle={{ width: 38, height: 38 }}
            />
          ) : (
            <Flex
              direction="column"
              gap={16}
            >
              {containsServiceType(ServiceType.consultation) && (
                <ServiceCard
                  title="Book a Consultation Call"
                  subtitle="Get high-quality, personalized travel advice and expert answers to any questions you have."
                  iconType={SvgIconType.VIDEO_CAMERA_ICON}
                  isMobileScreen={isMobileScreen}
                  redirectToService={() =>
                    redirect(`/seller/services/consultation/@${user.username}`)
                  }
                />
              )}
              {containsServiceType(ServiceType.itinerary) && (
                <ServiceCard
                  title="Get a Custom Itinerary"
                  subtitle="Get a day-by-day trip plan custom made for your preferences and needs."
                  iconType={SvgIconType.ROUTE_ICON}
                  isMobileScreen={isMobileScreen}
                  redirectToService={() => redirect(`/seller/services/itinerary/@${user.username}`)}
                />
              )}
              {containsServiceType(ServiceType.recs) && (
                <ServiceCard
                  title="Get Curated Recommendations"
                  subtitle="Get a list of spots hand-picked for you based on your preferences and needs."
                  iconType={SvgIconType.PHOTO_LIBRARY_ICON}
                  isMobileScreen={isMobileScreen}
                  redirectToService={() => redirect(`/seller/services/recs/@${user.username}`)}
                />
              )}
            </Flex>
          )}

          {(isLoading ||
            (containsServiceType(ServiceType.consultation) &&
              containsServiceType(ServiceType.itinerary) &&
              containsServiceType(ServiceType.recs))) && <CustomDivider />}

          {isMobileScreen && user.sellerIntroVideo && (
            <>
              <VideoPlayer />
              <CustomDivider />
            </>
          )}

          <Flex
            direction="column"
            gap={32}
          >
            <ProfileCardSection
              name={user.name}
              photoWidth={isMobileScreen ? 100 : 168}
              photoHeight={isMobileScreen ? 113 : 224}
              photoUrl={user.sellerPhoto}
              homeLocation={user.home?.name}
            />

            <SellerInfoSection
              maw={isMobileScreen ? undefined : 470}
              user={user as ProfileSummaryType}
              hideAvatarAndSocial
            />
          </Flex>

          <CustomDivider />

          <ThatchButton
            label="Browse my travel shop →"
            sx={{ minHeight: 40 }}
            onClick={() =>
              window.location.pathname === `/@${user.username}`
                ? onClose()
                : redirect(`/@${user.username}`)
            }
            variant="outline"
          />
        </Flex>
      </Flex>
    </Modal>
  )
}
